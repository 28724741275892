import { AuthBindings, useNavigation } from "@refinedev/core";
import { API_URL, DOMAIN_URL } from "./constants";
import axios from "axios";
import { AuthHelper } from "@pankod/refine-strapi-v4";
const mockUsers = [{ email: "demo@test.com" }];

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(API_URL + "/api");

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
      const apiLGN = await axios.post(API_URL + `/api/auth/local`,{
        identifier: email,
        password: password
      },
      {headers: {}}).then((res: any) => {
        let dataLoad: any = res?.data;
        localStorage.setItem("AUTH", dataLoad?.jwt);
        localStorage.setItem("USER", dataLoad?.user?.id);
        setTimeout(() => {
          let checked = localStorage?.getItem("AUTH");
          if(checked){
            window.location.href = `${DOMAIN_URL}/check-in`;
          }
        }, 500);    
      });
    
    return {
      success: false,
      error: {
        message: "Login failed",
        name: "Invalid email or password",
      },
    };
  },
  logout: async () => {
    localStorage.removeItem("AUTH");
    localStorage.clear();

    return {
      success: true,
      redirectTo: "/",
    };
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
  check: async () => {
    const TOKEN = localStorage.getItem("AUTH");
    let page = window?.location?.href.split("/");
    let pageTOKEN:any = page[3] ? page[3] : undefined;

    if(TOKEN){
      if(pageTOKEN !== "check-in" && pageTOKEN !== "profile" && pageTOKEN !== "history" && pageTOKEN !== "market" && pageTOKEN !== "games" && pageTOKEN !== "leave-work"){
        setTimeout(() => {
            window.location.href = `${DOMAIN_URL}/check-in`;
        }, 500);
      }
      return {
        authenticated: true,
      };
    }else{
      if(pageTOKEN && pageTOKEN !== "detail-id-card"){
        window.location.href = `${DOMAIN_URL}`;
      }

      return {
        authenticated: false,
        error: {
          message: "Check failed",
          name: "Token not found",
        },
        logout: true,
        redirectTo: "/login",
      };
    }
  },
  getPermissions: async () => {
    const role = await localStorage.getItem('role');
    return {
      role : role,
      permission : {}
    }
  },
  getIdentity: async () => {
    const token = localStorage.getItem("AUTH");
    const userID = localStorage.getItem("USER");

    if (!token) {
      return null;
    }

    let fileUser: any = undefined;
    const apiGetprofile = await axios.get(API_URL + `/api/v1/user_profile/findUserData?id=${userID}`,
    {headers: {}}).then((res: any) => {
      fileUser = res?.data;
    });

    if(fileUser){
      return {
        user: fileUser?.user,
      };
    }

    return null;
  },
};
