import { IResourceComponentsProps, useCustom, useLogout, useTranslate } from "@refinedev/core";
import { Carousel, Col, ConfigProvider, Divider, Empty, Row, Spin, Tag } from "antd";
import { useState } from "react";
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { API_URL, DOMAIN_URL } from "../../constants";
import "./styles.css";
import { MenuCustom } from "components/menu_footer";
import { axiosInstance } from "@refinedev/strapi-v4";

export const MarketPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const TOKEN: any = localStorage.getItem("AUTH");
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [userProfile, setuserProfile] = useState<any>();
    const [point, setpoint] = useState();
    const [themeCurrent, setthemeCurrent] = useState();

    const { mutate: mutateLogout } = useLogout();

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                setuserProfile((pre: any) => dataLoad?.user_profile);
                setthemeCurrent((pre: any) => dataLoad?.user_profile?.current_theme || "styles-main");

                settk(!tk);
                await getTheme(dataLoad?.user_profile?.current_theme || "styles-main");
                await getPoint(dataLoad?.user_profile?.id);
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            },
            onError(err: any){
                localStorage?.clear();
                mutateLogout();
            }
        },
    });
    
    const contentStyle: React.CSSProperties = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#272625',
    };

    const theme: any = [
        {
            id: 1,
            name: "Default",
            value: "styles-main",
            status: "allow"
        },
        {
            id: 2,
            name: "Abstract",
            value: "styles-abstract",
            status: "allow"
        },
        {
            id: 3,
            name: "Cyber",
            value: "styles-cyber",
            status: "allow"
        },
        {
            id: 4,
            name: "Natural",
            value: "styles-natural",
            status: "allow"
        }
    ]

    const renderTheme: any = (theme: any) => {
        return (
            <Col span={12} className="gutter-row">
                <div
                    className={`${themeCurrent == theme?.value ? "theme-items checked" : "theme-items"}`}
                    onClick={() => {if(theme?.status == "allow"){bodyTheme(theme?.value)}}}
                >
                    <div style={{color: "#fff"}}>
                        <span>{theme?.name}</span>
                    </div>
                </div>
            </Col>
        )
    }

    function bodyTheme(value: any){
        let body: any = {current_theme: value};
        setthemeCurrent((pre: any) => value);
        settk(!tk);
        setTimeout(() => {
            setLoading(true);
            updateProfile(body);
            getTheme(value);
        }, 300);
    }

    function updateProfile(body: any){
        axiosInstance.put(`${API_URL}/api/user-profile/update_profile?id=${userProfile?.id}`, body, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((res) => {
            window.location.reload();
        })
        .catch(error => {
            console.error('error ',error);
        });
    }

    async function getTheme(value: any){
        let theme: any = value;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `${DOMAIN_URL}/theme/${theme}.css`;
        document.head.appendChild(link);
    }

    async function getPoint(id: any){
        axiosInstance.get(`${API_URL}/api/user-point/find_point_by_profile?id=${id}`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((res) => {
            let response: any = res?.data?.point;
            setpoint((pre: any) => response.toFixed(2));
            settk(!tk);
        })
        .catch(error => {

        });
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
            <Spin 
                spinning={isLoading} 
                indicator={
                    <div className="custom-spin-dot">
                        <LoadingOutlined style={{marginRight: "15px"}}/>
                        <span>{t("โหลดข้อมูล")}</span>
                    </div>
                }
                fullscreen={isLoading}
            >
                <div className="layout-market">
                    <div className="body-container-market">
                        <div className="panel-header">
                            <div className="font-header">
                                <span className="main">{t("i24")}</span>
                                <span className="sub-main">{t("HOURS")}</span>
                                <span className="sub-main">{t(" | MARKET")}</span>
                            </div>
                        </div>
                        <div className="panel-body panel-full-h custom-scroll">
                            <div style={{padding: "15px"}}>

                                <div>
                                    <div>
                                        <span style={{marginRight: "5px"}}><UserOutlined /></span>
                                        <span style={{marginRight: "5px"}}>{userProfile?.first_name}</span>
                                        <span>{userProfile?.last_name}</span>
                                        <span style={{float: "right"}}><Tag color="gold">{t("Point: ") + point}</Tag></span>
                                    </div>
                                </div>

                                <Divider />

                                <div style={{marginBottom: "1rem"}}>
                                    <div style={{marginBottom: "1rem"}}><Tag color="#ff0000">{t("NEWS")}</Tag>{t("UPDATE NEWS")}</div>
                                    <section>
                                        <Carousel autoplay={true} dotPosition="left">
                                            <div>
                                                <div style={contentStyle}>
                                                    <div>
                                                        <Tag color="#0093ff">{t("NEWS")}</Tag>
                                                        {t("ระบบธีมใหม่จะเข้ามาในเร็วๆนี้...")}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={contentStyle}>
                                                    <div>
                                                        <Tag color="#0093ff">{t("NEWS")}</Tag>
                                                        {t("ระบบแจ้งลาออนไลน์จะเข้ามาในเร็วๆนี้...")}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={contentStyle}>
                                                    <div>
                                                        <Tag color="#0093ff">{t("NEWS")}</Tag>
                                                        {t("เตรียมพบกับระบบเกมส์เพิ่มสีสันในการทำงาน...")}
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel>
                                    </section>
                                </div>

                                <Divider />
                                
                                <div className="theme-panel" style={{marginBottom: "1rem"}}>
                                    <div style={{marginBottom: "1rem"}}>{t("FREE THEME")}</div>
                                    <Row gutter={[12, 12]} style={{width: "100%"}}>
                                        {theme?.map((item: any) => {
                                            return (renderTheme(item))
                                        })}
                                    </Row>
                                </div>

                                <Divider />

                                <div>
                                    <div style={{marginBottom: "1rem"}}> <Tag color="#ff0000">{t("NEWS")}</Tag>{t("NEWS THEME")}</div>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Comming soon..."/>
                                </div>
  
                                <Divider />

                                <div>
                                    <div style={{marginBottom: "1rem"}}> <Tag color="#ff0000">{t("NEWS")}</Tag>{t("GAMES SPACE")}</div>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Comming soon..."/>
                                </div>

                                <Divider />

                                <div>
                                    <div style={{marginBottom: "1rem"}}> <Tag color="#ff0000">{t("NEWS")}</Tag>{t("AVATAR")}</div>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Comming soon..."/>
                                </div>
                            </div>
                        </div>
                        <div className="panel-menu"><MenuCustom/></div>
                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}