import { useCustomMutation, useLogin, useNavigation, useTranslate } from "@refinedev/core";
import { Button, Col, Form, Input, Row, Spin, notification, ConfigProvider, message } from "antd";
import { API_URL, DOMAIN_URL } from "../../../constants";
import { useState } from "react";
import "./styles.css";
import { LoadingOutlined, HistoryOutlined, CaretRightOutlined } from '@ant-design/icons';

export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const LoginPage: React.FC = () => {
    const t = useTranslate();
    const [form] = Form.useForm<ILoginForm>();
    const { mutate: login } = useLogin<ILoginForm>();
    const { mutate: clogin } = useCustomMutation<any>();

    localStorage.clear();

    const [isLoading, setLoading] = useState<boolean>(false);

    const faildNoti = () => {
        message.error(`E-mail or Password is incorrect`);
    };

    return (
        <div id="container-page-bg">
            <div className="panel-form">
                <ConfigProvider
                    theme={{
                        token: {
                            colorBgMask: "rgba(27, 26, 24, 1)",
                            colorPrimary: "#f07d51",
                        },
                    }}
                >
                    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: "60px" }} />} fullscreen={isLoading}>
                        <Form<ILoginForm>
                            layout="vertical"
                            form={form}
                            onFinish={async (values: any) => {
                                setLoading(true);
                                clogin({
                                    url:  API_URL + `/api/auth/local`,
                                    method: "post",
                                    values: {
                                        identifier: values.email,
                                        password: values.password,
                                    },
                                        errorNotification : false
                                    },
                                    {
                                    onError: (error, variables, context) => {
                                        setLoading(false);
                                        faildNoti();
                                    },
                                    onSuccess: async (data:any, variables, context) => {
                                        let dataLoad: any = data?.data;
                                        setTimeout(() => {
                                            localStorage.setItem("AUTH", dataLoad?.jwt);
                                            login(values);
                                        }, 500);       
                                    },
                                })
                            }}
                            requiredMark={false}
                            initialValues={{
                                remember: false,
                            }}
                        >
                            <div style={{marginBottom: "30px"}}>
                                <div style={{textAlign: "center", fontSize: "40px", fontWeight: "bold"}}>
                                    <span style={{color: "#f07d51", marginRight: "10px"}}><HistoryOutlined /></span>
                                    <span style={{color: "#f07d51"}}>{t("i25")}</span>
                                    <span style={{color: "#fff"}}>{t("HOURS")}</span>
                                </div>
                                <div style={{textAlign: "center", color: "#fff"}}>{t("ยินดีต้อนรับครับ ขอให้เป็นวันที่สดใส :)")}</div>
                            </div>

                            <Form.Item
                                name="email"
                                className="mb-30px"
                                label={<span style={{color: "#fff", fontSize: "16px"}}><CaretRightOutlined />{"Email"}</span>}
                                rules={[{ required: true, message: "Please enter email" }]}
                            >
                                <Input
                                    className="font-size16 input-h45"
                                    size="large" 
                                    placeholder="Email"
                                    type="email"
                                    style={{boxShadow: "rgba(240,125,81, 1) 2px 2px 1px ,rgba(0, 0, 0, 0.2) 5px 5px 5px", border: "unset"}}
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                className="mb-10px"
                                label={<span style={{color: "#fff", fontSize: "16px"}}><CaretRightOutlined />{"Password"}</span>}
                                rules={[{ required: true, message: "Please enter password" }]}
                            >
                                <Input.Password
                                    className="font-size16 input-h45"
                                    size="large"
                                    type="password"
                                    placeholder="Password"
                                    style={{boxShadow: "rgba(240,125,81, 1) 2px 2px 1px ,rgba(0, 0, 0, 0.2) 5px 5px 5px", border: "unset"}}
                                />
                            </Form.Item>

                            <div className="mt-5rem">
                                <Button
                                    className="btn-sign-in"
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    style={{boxShadow: "rgba(0, 0, 0, 0.1) 15px 10px 10px"}}
                                >
                                    {t("Sign in")}
                                </Button>
                            </div>

                        </Form>

                        <Row gutter={24} style={{visibility: "hidden"}}>
                            <Col className="gutter-row" span={12}>
                                <Button
                                    className="btn-lgn-social"
                                >
                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img style={{width: "40px", marginRight: "10px"}} src={`${DOMAIN_URL}/images/icon/icon-google.png`} alt="icn"/>
                                        <div className="font-size18">{t("Login with Google")}</div>
                                    </div>
                                </Button>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Button
                                    className="btn-lgn-social"
                                >
                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img style={{width: "35px", marginRight: "10px"}} src={`${DOMAIN_URL}/images/icon/icon-facebook.png`} alt="icn"/>
                                        <div className="font-size18">{t("Login with Facebook")}</div>
                                    </div>
                                </Button>
                            </Col>
                        </Row>
                    </Spin>
                </ConfigProvider>
            </div>
        </div>
    )
}