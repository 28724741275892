// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-center > .panel-back {
    background-color: #fff;
    max-width: 100px;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-10px);
    border-radius: 50px;
    /* border-radius: 5px; */
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 5px;
}

.item-center > .panel-back > .panel-btn {
    cursor: pointer;
    max-width: 80px;
    max-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    /* border-radius: 5px; */
    transition: 0.3s;
    padding: 10px;
}

/* theme */

.item-center > .panel-back > .panel-btn {
    background-color: red;
}

.item-center > .panel-back > .panel-btn:hover {
    background-color: blue;
    transition: 0.3s;
}

.menu-custom-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}

.item-menu {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/menu_footer/styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,4BAA4B;IAC5B,mBAAmB;IACnB,wBAAwB;IACxB,kBAAkB;IAClB,mDAAmD;IACnD,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,wBAAwB;IACxB,gBAAgB;IAChB,aAAa;AACjB;;AAEA,UAAU;;AAEV;IACI,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,+CAA+C;AACnD;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".item-center > .panel-back {\n    background-color: #fff;\n    max-width: 100px;\n    max-height: 100px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transform: translateY(-10px);\n    border-radius: 50px;\n    /* border-radius: 5px; */\n    position: absolute;\n    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;\n    padding: 5px;\n}\n\n.item-center > .panel-back > .panel-btn {\n    cursor: pointer;\n    max-width: 80px;\n    max-height: 80px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 50px;\n    /* border-radius: 5px; */\n    transition: 0.3s;\n    padding: 10px;\n}\n\n/* theme */\n\n.item-center > .panel-back > .panel-btn {\n    background-color: red;\n}\n\n.item-center > .panel-back > .panel-btn:hover {\n    background-color: blue;\n    transition: 0.3s;\n}\n\n.menu-custom-grid {\n    display: grid;\n    grid-template-columns: auto auto auto auto auto;\n}\n\n.item-menu {\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
